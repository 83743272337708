import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Vimeo from "@u-wave/react-vimeo"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { Hero } from "@components/Hero"
import { HeroImage, Intro, Category, MobileDesign, Pagination, Section } from "@components/Work"
import { Carousel } from "@components/Carousel"
import { CursorMarker } from "@components/CursorMarker"
import { Arrow } from "@components/UI"
import { splitText } from "@utils"
import FontsDesktop from "@svg/work/bpm/fonts-desktop.svg"
import FontsTablet from "@svg/work/bpm/fonts-tablet.svg"
import FontsMobile from "@svg/work/bpm/fonts-mobile.svg"
import workData from "@json/work.json"

const BpmPage = ({ data }) => {
  const {
    webpageOneImage,
    webpageTwoImage,
    mobileOneImage,
    mobileTwoImage,
    mobileThreeImage,
    logoTwoImage,
    logoThreeImage,
    logoFourImage,
    printOneDesktopImage,
    printOneTabletImage,
    printTwoDesktopImage,
    printTwoTabletImage,
    printThreeDesktopImage,
    printThreeTabletImage,
  } = data

  const { name, headline, theme, url } = workData["bpm"]
  const { themeColor } = usePageSetup({
    themeColor: theme.background,
    navigationColor: theme.navigationColor,
    displayGeneralForm: false,
  })

  return (
    <Layout>
      <Seo title={name} description={headline} />
      {/* HERO */}
      <section className={themeColor}>
        <Hero className="pb-clamp-36-54 pt-clamp-16-26">
          <Hero.Title>
            <h1 className="tracking-normal text-white text-18">{name}</h1>
            <h2
              data-aos="stagger"
              className="text-work-headline mt-clamp-6-8"
              dangerouslySetInnerHTML={{
                __html: splitText({
                  "text-white": "A bold new identity for a firm on the rise.",
                }),
              }}
            />
          </Hero.Title>
        </Hero>
      </section>

      <Arrow colorTop="bg-white" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

      <main className="overflow-hidden">
        <HeroImage client="bpm" />
        <Category>Employer branding, Corporate branding</Category>
        <Intro>
          <Intro.Overview>
            <Intro.Title>
              How does a fast-growing CPA firm continue to build momentum? Build a new brand that instantly telegraphs
              their value to customers and employees.
            </Intro.Title>
            <div className="mt-10">
              <p>
                BPM is one of America’s fastest-growing tax, accounting, and advisory firms, offering leading-edge
                services and driven by a real passion for helping others to be successful in work and in life.
              </p>
              <p>
                While they had grown into one of the nation’s most progressive firms, their story and visual identity
                lagged behind. So we developed a new brand that made it easy for prospective clients and employees to
                quickly understand what sets them apart.
              </p>
            </div>
          </Intro.Overview>
          <Intro.Deliverables url={url}>
            <li>Corporate branding</li>
            <li>Employer branding</li>
            <li>Logo development</li>
            <li>Messaging</li>
            <li>Visual identity</li>
            <li>Brand architecture</li>
            <li>Brand launch & development</li>
            <li>Website design</li>
            <li>Campaign development</li>
            <li>Guidelines &amp; playbook</li>
            <li>Design &amp; copywriting</li>
            <li>Video</li>
          </Intro.Deliverables>
        </Intro>

        <section className="mt-clamp-34-29">
          <div className="container flex justify-center">
            <div className="w-full lg:w-10/12">
              <div data-aos="fade-up">
                <div className="overflow-hidden rounded-lg shadow">
                  <Vimeo video="790166019" controls={false} responsive muted autoplay loop background />
                </div>
              </div>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

        <section className="pt-clamp-48-62">
          <Section className="col-span-9 xl:col-span-8">
            <Section.Title>A new mark that hits the spot.</Section.Title>
            <Section.Description>
              <p>
                The original BPM logo was a simple letter mark, which limited the firm’s ability to make a memorable
                impression and build brand equity.
              </p>
              <p>
                So we developed one that tells the story of their three lines of services coming together to deliver
                unmatched levels of capability—seen as three waves converging to a central point—and their dedication to
                personalized service, reflected in the subtle suggestion of a fingerprint in the design.
              </p>
            </Section.Description>
          </Section>
          <div className="mt-clamp-17-22">
            <div className="container relative flex">
              <CursorMarker>
                <CursorMarker.Gallery />
              </CursorMarker>
              <div className="w-full sm:w-5/6 lg:w-1/2">
                <Carousel>
                  <Carousel.Swiper spaceBetween={16} centeredSlides={false}>
                    <Carousel.Slide>
                      <div data-aos="fade-up" className="w-full pointer-events-none">
                        <Vimeo
                          className="vimeo-square"
                          video="790538692"
                          controls={false}
                          responsive
                          muted
                          autoplay
                          loop
                          background
                        />
                      </div>
                    </Carousel.Slide>
                    <Carousel.Slide>
                      <div data-aos="fade-up" data-aos-delay="200" className="w-full pointer-events-none">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(logoTwoImage)}
                          alt="Logo 2"
                        />
                      </div>
                    </Carousel.Slide>
                    <Carousel.Slide>
                      <div data-aos="fade-up" data-aos-delay="400" className="w-full pointer-events-none">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(logoThreeImage)}
                          alt="Logo 3"
                        />
                      </div>
                    </Carousel.Slide>
                    <Carousel.Slide>
                      <div data-aos="fade-up" data-aos-delay="600" className="w-full pointer-events-none">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(logoFourImage)}
                          alt="Logo 4"
                        />
                      </div>
                    </Carousel.Slide>
                  </Carousel.Swiper>
                </Carousel>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container flex justify-center pt-clamp-20-37">
            <div className="w-full xl:w-10/12">
              <div className="w-full">
                <h3 data-aos="fade-up" className="text-18">
                  Colors
                </h3>
              </div>
              <div data-aos="fade-up">
                <div className="grid w-full mt-16 sm:grid-cols-3">
                  <div className="w-full pb-5 pl-5 text-white pt-39" style={{ background: "#002589" }}>
                    <span className="font-bold text-18">#002589</span>
                  </div>
                  <div className="w-full pb-5 pl-5 text-white pt-39" style={{ background: "#00A5E2" }}>
                    <span className="font-bold text-18">#00A5E2</span>
                  </div>
                  <div className="w-full pb-5 pl-5 text-white pt-39" style={{ background: "#CDF2FF" }}>
                    <span className="font-bold text-18">#CDF2FF</span>
                  </div>
                </div>

                <div className="grid w-full text-white sm:grid-cols-3">
                  <div className="pb-5 pl-5 pt-15" style={{ background: "#E0FF9C" }}>
                    <span className="font-bold text-18 text-gray-darkest">#E0FF9C</span>
                  </div>
                  <div className="pb-5 pl-5 pt-15" style={{ background: "#A82999" }}>
                    <span className="font-bold text-18">#A82999</span>
                  </div>
                  <div className="pb-5 pl-5 pt-15" style={{ background: "#707070" }}>
                    <span className="font-bold text-18">#707070</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-clamp-34-42">
          <div className="container flex justify-center">
            <div className="w-full xl:w-10/12">
              <div className="w-full">
                <h3 data-aos="fade-up" className="text-18">
                  Brand typography
                </h3>
              </div>
              <div data-aos="fade-up" className="mt-6">
                <div className="display-desktop">
                  <FontsDesktop />
                </div>
                <div className="display-tablet">
                  <FontsTablet />
                </div>
                <div className="display-mobile">
                  <FontsMobile />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="pt-clamp-20-36">
            <Section className="col-span-10 xl:col-span-9">
              <Section.Title>Out with the old, in with the bold.</Section.Title>
              <Section.Description>
                <p>
                  BPM’s messaging and visual identity had grown quite dated, so we took a clean-sheet approach to
                  building their new brand.
                </p>
                <p>
                  We brought together people from across the company for an interactive branding workshop. Together, we
                  created a new brand story that quickly communicates what sets them apart from their competitors.
                </p>
                <p>
                  We also modernized their visual identity with a bold new color palette, people-focused photography,
                  and unique brand textures.
                </p>
                <p>
                  Our new design system perfectly projects BPM’s unique position as a firm that takes a forward-thinking
                  approach to doing business while remaining a trusted, results-driven partner.
                </p>
              </Section.Description>
            </Section>

            <div className="container relative mt-clamp-18-25">
              <Carousel>
                <CursorMarker>
                  <CursorMarker.Print />
                </CursorMarker>
                <Carousel.Swiper
                  breakpoints={{
                    1436: {
                      spaceBetween: 80,
                      slidesPerView: "auto",
                    },
                  }}
                >
                  <Carousel.Slide>
                    <div data-aos="fade-up">
                      <Carousel.Print>
                        <div className="display-mobile-tablet">
                          <GatsbyImage
                            className="w-full h-full"
                            objectFit="contain"
                            image={getImage(printOneTabletImage)}
                            alt="Print 1"
                          />
                        </div>
                        <div className="display-desktop">
                          <GatsbyImage
                            className="w-full h-full"
                            objectFit="contain"
                            image={getImage(printOneDesktopImage)}
                            alt="Print 1"
                          />
                        </div>
                      </Carousel.Print>
                    </div>
                  </Carousel.Slide>
                  <Carousel.Slide>
                    <div data-aos="fade-up" data-aos-delay="200">
                      <Carousel.Print>
                        <div className="display-mobile-tablet">
                          <GatsbyImage
                            className="w-full h-full"
                            objectFit="contain"
                            image={getImage(printTwoTabletImage)}
                            alt="Print 2"
                          />
                        </div>
                        <div className="display-desktop">
                          <GatsbyImage
                            className="w-full h-full"
                            objectFit="contain"
                            image={getImage(printTwoDesktopImage)}
                            alt="Print 2"
                          />
                        </div>
                      </Carousel.Print>
                    </div>
                  </Carousel.Slide>
                  <Carousel.Slide>
                    <div data-aos="fade-up" data-aos-delay="300">
                      <Carousel.Print>
                        <div className="display-mobile-tablet">
                          <GatsbyImage
                            className="w-full h-full"
                            objectFit="contain"
                            image={getImage(printThreeTabletImage)}
                            alt="Print 3"
                          />
                        </div>
                        <div className="display-desktop">
                          <GatsbyImage
                            className="w-full h-full"
                            objectFit="contain"
                            image={getImage(printThreeDesktopImage)}
                            alt="Print 3"
                          />
                        </div>
                      </Carousel.Print>
                    </div>
                  </Carousel.Slide>
                </Carousel.Swiper>
              </Carousel>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

        <section className="pt-clamp-35-62">
          <Section className="col-span-10 xl:col-span-9">
            <Section.Title>An employer brand built to connect. </Section.Title>
            <Section.Description>
              <p>
                Finally, we developed a powerful talent story rooted in BPM’s new corporate brand identity—but geared
                specifically to talent.
              </p>
              <p>
                We created a unique manifesto, an employee value proposition, messaging themes tailored to specific
                talent audiences, and other employee-focused content.
              </p>
              <p>
                We put it all together in a playbook that allows recruiters and HR to tell a consistent, compelling, and
                concise story of why people should choose to work and stay at BPM.
              </p>
            </Section.Description>
          </Section>
          <div className="container flex justify-center mt-clamp-18-25">
            <div className="w-full lg:w-10/12">
              <div data-aos="fade-up">
                <div className="overflow-hidden rounded-lg shadow">
                  <Vimeo video="790183973" controls={false} responsive muted autoplay loop background />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="mt-clamp-22-29" style={{ background: "#CDF2FE" }}>
            <div className="container relative flex justify-center">
              <div className="absolute inset-0">
                <div className="w-full aspect-w-1436 aspect-h-136 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
              </div>
              <div className="absolute inset-x-0 bottom-0">
                <div className="w-full aspect-w-1436 aspect-h-243 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
              </div>
              <div className="flex flex-col w-full lg:flex-row md:w-10/12 gap-x-7">
                <div className="flex w-full lg:block lg:w-1/2">
                  <div className="w-full sm:w-5/6 lg:w-full">
                    <div data-aos="fade-up" className="overflow-hidden rounded shadow-xl">
                      <GatsbyImage image={getImage(webpageOneImage)} alt="AD webpage - 1" />
                    </div>
                  </div>
                </div>
                <div className="flex w-full lg:block lg:w-1/2 pt-clamp-14-37">
                  <div className="hidden w-full lg:block aspect-w-656 aspect-h-270"></div>
                  <div className="w-full ml-auto sm:w-5/6 lg:w-full">
                    <div data-aos="fade-up" className="overflow-hidden rounded shadow-xl">
                      <GatsbyImage image={getImage(webpageTwoImage)} alt="AD webpage - 2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative pt-clamp-15-42 pb-clamp-35-71">
          <Section className="col-span-9 xl:col-span-8">
            <Section.Title>Bringing the “firm of the future” out of the past.</Section.Title>
            <Section.Description>
              <p>
                BPM has a long-held vision of becoming the “firm of the future,” a partner who can be relied upon to
                bring the very latest ideas and innovation to their clients and help them to succeed in work and in
                life.
              </p>
              <p>
                And while the firm had made great strides in achieving this vision, their brand had held them back in
                the past. JK was grateful for the opportunity to work closely with people from across the organization
                to truly understand who they are and craft a new story and visual identity that sets BPM up for success
                now … and into the future.
              </p>
            </Section.Description>
          </Section>
          <div className="mt-32">
            <MobileDesign>
              <MobileDesign.Phone className="phone-1">
                <GatsbyImage
                  objectFit="contain"
                  className="overflow-hidden shadow rounded-3xl"
                  image={getImage(mobileOneImage)}
                  alt="Weichert mobile - 1"
                />
              </MobileDesign.Phone>
              <MobileDesign.Phone className="phone-2 sm:pt-32">
                <GatsbyImage
                  objectFit="contain"
                  className="overflow-hidden shadow rounded-3xl"
                  image={getImage(mobileTwoImage)}
                  alt="Weichert mobile - 2"
                />
              </MobileDesign.Phone>
              <MobileDesign.Phone className="phone-3">
                <GatsbyImage
                  objectFit="contain"
                  className="overflow-hidden shadow rounded-3xl"
                  image={getImage(mobileThreeImage)}
                  alt="Weichert mobile - 3"
                />
              </MobileDesign.Phone>
            </MobileDesign>
          </div>
        </section>

        <Arrow colorTop="bg-gray-darkest" colorBottom="bg-white" fillColor="text-white" />

        <Pagination client="bpm" />
      </main>
    </Layout>
  )
}

export default BpmPage

export const bpmQuery = graphql`
  query BpmPageQuery {
    logoThree: file(relativePath: { eq: "work/lumendi/logo-03.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    webpageOneImage: file(relativePath: { eq: "work/bpm/webpage-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    webpageTwoImage: file(relativePath: { eq: "work/bpm/webpage-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    mobileOneImage: file(relativePath: { eq: "work/bpm/mobile-01.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    mobileTwoImage: file(relativePath: { eq: "work/bpm/mobile-02.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    mobileThreeImage: file(relativePath: { eq: "work/bpm/mobile-03.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    logoTwoImage: file(relativePath: { eq: "work/bpm/logo-02.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    logoThreeImage: file(relativePath: { eq: "work/bpm/logo-03.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    logoFourImage: file(relativePath: { eq: "work/bpm/logo-04.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printOneDesktopImage: file(relativePath: { eq: "work/bpm/print-01-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printOneTabletImage: file(relativePath: { eq: "work/bpm/print-01-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printTwoDesktopImage: file(relativePath: { eq: "work/bpm/print-02-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printTwoTabletImage: file(relativePath: { eq: "work/bpm/print-02-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printThreeDesktopImage: file(relativePath: { eq: "work/bpm/print-03-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printThreeTabletImage: file(relativePath: { eq: "work/bpm/print-03-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }

    fontsDesktopImage: file(relativePath: { eq: "work/xcelis/fonts-desktop.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    fontsTabletImage: file(relativePath: { eq: "work/xcelis/fonts-tablet.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    fontsMobileImage: file(relativePath: { eq: "work/xcelis/fonts-mobile.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
